<template>
  <form @submit.prevent="submit">
    <loading-overlay
      :active="Loading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      @update:show="resetForm"
    >
      <CRow>
        <CCol sm="12" lg="12">
          <CInput
            v-uppercase
            size="sm"
            :label="$t('label.yard')"
            :placeholder="$t('label.yard')"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            :value="getYardName"
            addLabelClasses="text-right"
            disabled
          />
        </CCol>
        <CCol sm="12" lg="12">
          <CInput
            v-uppercase
            size="sm"
            :label="$t('label.name')"
            :placeholder="$t('label.name')"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            v-model="$v.subClass.YardSubClasificationName.$model"
            :is-valid="hasError($v.subClass.YardSubClasificationName)"
            :invalid-feedback="errorMessage($v.subClass.YardSubClasificationName)"
            addLabelClasses="required text-right"
          />
        </CCol>
        <CCol sm="12" lg="12">
          <CRow class="row px-6 m-0 mb-3"> 
            <CCol sm="12" lg="3" class="px-2">
              <label class="col-form-label-sm col-sm-12 text-right required px-0">{{`${$t('label.width')} (M)`}}</label>
            </CCol>
            <CCol sm="12" lg="6" class="pr-0 pl-1 m-0 col-sm-12 col-lg-8 col-xl-8">
              <money
                size="sm"
                v-bind="measure" 
                class="form-control col-sm-12"
                v-model.trim="$v.subClass.Width.$model"
                @blur="$v.subClass.Width.$touch()"
                addLabelClasses="required text-right"
                :class="ValidateFormControl(subClass.Width)"
                value="0"
                maxlength="9"
              ></money>
              <div>
                <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((subClass.Width) < 0)">
                  {{ $t('label.ValueNotNegative') }}
                </CCol>
                <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((subClass.Width == 0 || subClass.Width == '0,00') && valid==true)">
                  {{$t('label.required')}}
                </CCol>
              </div>
            </CCol>
          </CRow> 
          
        </CCol>
        <CCol sm="12" lg="12">
          <CRow class="row px-6 m-0 mb-3"> 
            <CCol sm="12" lg="3" class="px-2">
              <label class="col-form-label-sm col-sm-12 text-right required px-0">{{`${$t('label.largo')} (M)`}}</label>
            </CCol>
            <CCol sm="12" lg="6" class="pr-0 pl-1 m-0 col-sm-12 col-lg-8 col-xl-8">
              <money
                size="sm"
                v-bind="measure" 
                class="form-control col-sm-12"
                v-model.trim="$v.subClass.Length.$model"
                @blur="$v.subClass.Length.$touch()"
                addLabelClasses="required text-right"
                :class="ValidateFormControl(subClass.Length)"
                value="0"
                maxlength="9"
              ></money>
              <div>
                <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((subClass.Length) < 0)">
                  {{ $t('label.ValueNotNegative') }}
                </CCol>
                <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((subClass.Length == 0 || subClass.Length == '0,00') && valid==true)">
                  {{$t('label.required')}}
                </CCol>
              </div>
            </CCol>
          </CRow> 
        </CCol> 
        <CCol sm="12" lg="12">
          <CRow class="row px-6 m-0 mb-3"> 
            <CCol sm="12" lg="3" class="px-2">
              <label class="col-form-label-sm col-sm-12 text-right required px-0">{{`AREA (M²)`}}</label>
            </CCol>
            <CCol sm="12" lg="6" class="pr-0 pl-1 m-0 col-sm-12 col-lg-8 col-xl-8">
              <money
                v-bind="measure" 
                class="form-control col-sm-12"
                style="max-height: 80%;"
                v-model.trim="$v.subClass.Area.$model"
                @blur="$v.subClass.Area.$touch()"
                addLabelClasses="required text-right"
                :class="ValidateFormControl(subClass.Area)"
                value="0"
                :value.sync="calculateArea"
                disabled
                maxlength="9"
              ></money>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((subClass.Area) < 0)">
                {{ $t('label.ValueNotNegative') }}
              </CCol>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((subClass.Area) > 50000)">
                {{ $t('label.exceededArea') }}
              </CCol>
              <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((subClass.Area == 0 || subClass.Area == '0,00') && valid==true)">
                {{$t('label.required')}}
              </CCol>
            </CCol>
          </CRow> 
        </CCol>
        <CCol sm="12" lg="12" v-if="edit">
          <CSelect
            size="sm"
            :options="statusOptions"
            :value.sync="subClass.Status"
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            addLabelClasses="required text-right"
            :is-valid="statusSelectColor"
          />
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="edit ? statusConfirmation(subClassItems.FgActYardSubClasi, subClass.Status, submit) : submit()"
        >
        <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="resetForm"
        >
          <CIcon name="x" /><span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>
    </template>
    </CModalExtended>
  </form>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import UpperCase from '@/_validations/uppercase-directive';
import { mapState } from "vuex";
import { FormSubClass } from '@/_validations/master-yards/MasterOfYards';
import NumberInput from '@/components/numberinput';

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    Loading: false,
    subClass: {
      YardSubClasificationId: '',
      YardSubClasificationName: '',
      Width:'',
      Length:'',
      Area: '',
      Status: 0
    },
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 8,
    },
    valid: false
  };
}

function submit() {
  try {
    this.isSubmit = true;
    this.valid = true;
    this.Loading = true;
    this.$v.$touch();
    
    if (this.$v.$error || this.subClass.Width == '' || this.subClass.Width == '0,00' || this.subClass.Length == '' || this.subClass.Length == '0,00' || this.subClass.Area == '' || this.subClass.Area  == '0,00' || this.subClass.Area  > 50000) {
      this.isSubmit = false;
      this.valid = true;
      this.Loading = false;
      throw this.$t('label.errorsPleaseCheck');
    }
    let YardSubClasificationJson = this.edit ? {
        YardSubClasificationId: this.subClass.YardSubClasificationId,
        YardId: this.getYardId,
        YardSubClasificationName: this.subClass.YardSubClasificationName,
        Length: this.subClass.Length,
        Width: this.subClass.Width,
        SquareMeters: this.subClass.Area,
        Status: this.subClass.Status
      }:{
        YardId: this.getYardId,
        YardSubClasificationName: this.subClass.YardSubClasificationName,
        Length: this.subClass.Length,
        Width: this.subClass.Width,
        SquareMeters: this.subClass.Area,
      };

    let metodo = this.edit ? 'PUT':'POST';
    let ruta = this.edit ? 'YardSubClasification-update': 'YardSubClasification-insert';

    this.$http.ejecutar(metodo, ruta, YardSubClasificationJson, { root: 'YardSubClasificationJson' })
      .then(response => {
        this.$emit('submited');
        this.modalActive = false;
        this.getYards();
        
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: response.data.data[0].Response,
          type: "success"
        });
        this.resetForm();
      }).catch(err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).then(() => {
        this.isSubmit = false;
        this.Loading = false;
      });
  } catch (error) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: error,
      type: "error"
    });
  }
  
}

function getYards() {
  this.Loading = true;
  this.$http
    .get('Yard-list',{ CompanyBranchId: this.$store.state.auth.branch.CompanyBranchId})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.length != 0){
        let yardItem = listado.find((item)=> item.YardId === this.$store.state.yard.yardSelected.YardId);

        if(yardItem)
          this.$store.state.yard.yardSelected = yardItem;
      }
    })        
    .finally(() => {
      this.Loading = false;
    });
}

function toggle(newVal) {
  if (!newVal)
    this.resetForm();
  else{
    if (this.edit) {
        this.getData(this.subClassItems);
    }
  }
  this.modalActive = newVal;
}
function statusSelectColor() {
  return this.subClass.Status === 1;
}

function getData(val) {
  this.subClass.YardSubClasificationId = val.YardSubClasificationId;
  this.subClass.YardSubClasificationName = val.YardSubClasificationName;
  this.subClass.Length = val.Length;
  this.subClass.Width = val.Width;
  this.subClass.Area = val.SquareMeters;
  this.subClass.Status = val.FgActYardSubClasi?1:0;
  this.valid = true;
  this.$v.$touch();
}
function resetForm() {
  this.subClass.YardSubClasificationId = '';
  this.subClass.YardSubClasificationName = '';
  this.subClass.Length = '';
  this.subClass.Width = '';
  this.subClass.Area = '';
  this.subClass.Status = 0;
  this.valid = false;
  this.$emit('close');
  this.$v.$reset();
}

function ValidateFormControl(value) { 
  // value = this.ReverseFormat(value);
  if ((((value == 0 || value == '0,0000') && this.valid==true) || value < 0 || value > 50000 )) {
    return 'form-control is-invalid';
  }else{
    if((value == 0 || value == '0,0000') && this.valid==false){
      return 'form-control';
    }else{
      return 'form-control is-valid';
    }
  }
  

}

//computed
function getYardName() {
  return this.yardSelected ? this.yardSelected.YardName : '';
}

function getYardId() {
  return this.$store.state.yard.yardSelected.YardId ? this.$store.state.yard.yardSelected.YardId: "";
}

function calculateArea(){
  const {Length,Width} = this.subClass
  let total = 0;
  if(parseFloat(Length) > 0){
    if(total === 0)
      total = 0;
      total = parseFloat(Width) * parseFloat(Length);
    }
    if(parseFloat(Width) > 0){
      if(total === 0)
        total = 0;
        total = parseFloat(Length) * parseFloat(Width);
      }
      this.subClass.Area = total;
      return total;
}
export default {
  name: 'subclassification-modal',
  props: {
    modal: Boolean,
    edit: {
      type: Boolean,
      default: false,
    },
    subClassId: {
      type: String,
      default: "",
    },
    subClassItems: {
      type: Object,
      default: "",
    },
    title:{
      type: String,
      default: "",
    }

  },
  data,
  validations(){ return FormSubClass() },
  components: {
    NumberInput
  },
  mixins: [
    ModalMixin,
  ],
  directives: UpperCase,
  methods: {
    statusSelectColor,
    getData,
    submit,
    toggle,
    resetForm,
    ValidateFormControl,
    getYards
  },
  computed: {
    getYardName,
    calculateArea,
    getYardId,
    calculateArea,
    ...mapState({
      yardSelected: state => state.yard.yardSelected
    }),
  },
  watch: {
    modal: function(val){
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit('update:modal', val);
    },
  },
 
};
</script>